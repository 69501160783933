import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ChatCard from "./ChatCard";
import IconButton from "@mui/material/IconButton";
import { AttachFile, Cancel, More, Send } from "@mui/icons-material";
import MDAvatar from "components/MDAvatar";
import {
  Avatar,
  Box,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import { useMaterialUIController } from "context";
// import socketIO from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { getAllSupportMessage } from "redux/festures/supportandQnaSlice";
import { getAllSupportUsers } from "redux/festures/supportandQnaSlice";
import socket from "services/socket";
import { createMessage } from "redux/festures/supportandQnaSlice";
import { formatTimeAgo } from "Utils/dateFunc";
import MDInput from "components/MDInput";
import { debounceFunction } from "Utils/dateFunc";
import { formatDateString } from "Utils/dateFunc";
import { groupByDate } from "Utils/dateFunc";
import MDButton from "components/MDButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAllSupportPartner } from "redux/festures/supportandQnaSlice";

// import { io } from "socket.io-client";

const MessageTag = ({ data, children, side, ...rest }) => {
  // console.log(data, "Data");
  // console.log(side, ` -`, children);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <MDBox
      sx={({ palette: { dark, white, info } }) => ({
        width: "100%",
        // height: "100%",
        display: "flex",
        // float: chat?.messageType === "ADMIN " ? "right" : "left",
        alignItems: side ? "flex-start" : "flex-start",
        justifyContent: side ? "flex-end" : "flex-start",
        textAlign: side ? "right" : "left",
        p: 0.5,
      })}
    >
      <MDTypography
        // component="span"
        variant="h6"
        color="initial"
        sx={({ palette: { dark, white, info } }) => ({
          color: darkMode ? white.main : white.main,

          backgroundColor: darkMode ? info.main : info.main,
          maxWidth: 300,
          pl: 1,
          pr: 1.5,
          py: 1.5,
          display: "block",
          // backgroundColor: "#f8f8f8",
          borderRadius: !side ? "16px 16px 16px 2px" : "16px 16px 2px 16px",
          textTransform: "capitalize",
          textAlign: !side ? "left" : "right",
          opacity: !side ? 0.8 : 1,
          // float: !side ? "left" : "right",
          fontSize: 11.5,
          mb: 0.8,
        })}
        {...rest}
      >
        {data?.image && (
          <span
            style={{
              display: "inline-block",
              maxWidth: "280px",
              height: "auto",
              margin: "0 0.5rem",
            }}
          >
            <img
              className="Image"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              src={`${process.env.REACT_APP_URI}/${data?.image}`}
              alt={"img"}
            />
          </span>
        )}
        <br />
        {data?.message && data?.message}
        <br />
        <MDTypography
          variant="button"
          component="p"
          color="white"
          fontSize="10px"
          sx={{
            display: "flex",
            alignItems: side ? "flex-start" : "flex-start",
            justifyContent: side ? "flex-end" : "flex-start",
            textAlign: side ? "right" : "left",
          }}
        >
          {formatTimeAgo(data.createdAt)}
        </MDTypography>
      </MDTypography>
    </MDBox>
  );
};
const ChatName = ({ taredId, setState, active, name, setIsData }) => {
  const checkUser = () => {
    const userId = name?.userId;

    if (Array.isArray(userId)) {
      // Handle when userId is an array
      const rr = userId.find((x) => x?._id !== localStorage.getItem("admin_id"));
      return rr;
    } else if (typeof userId === "object" && userId !== null) {
      // Handle when userId is an object
      if (userId?._id !== localStorage.getItem("admin_id")) {
        return userId;
      }
    }

    // Return null if neither condition is met
    return null;
  };
  return (
    <ListItem
      onClick={() => {
        setIsData(name);
        if (taredId) {
          setState(taredId);
        }
      }}
    >
      <ListItemAvatar>
        <MDAvatar
          src={`${process.env.REACT_APP_URI}/${checkUser()?.image}`}
          alt={checkUser()?.fullName}
          shadow="md"
          objectFit="cover"
        />
      </ListItemAvatar>
      <ListItemText primary={checkUser()?.fullName || "N/A"} secondary="Jan 9, 2014" />
    </ListItem>
  );
};
const ChatComponent = ({ data }) => {
  const admin = localStorage.getItem("admin_id");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { isLoading, message, allUsers } = useSelector((state) => state.isSupport);
  const bottomEl = useRef(null);

  const scrollToBottom = (e) => {
    bottomEl.current?.scrollTo({
      top: bottomEl.current?.scrollHeight + 100,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    bottomEl.current?.scrollTo({
      top: bottomEl.current?.scrollHeight + 100,
      behavior: "smooth",
    });
  }, [isLoading, message]);

  const [userType, setUserType] = useState(
    data ? data?.userType : allUsers ? allUsers?.at(0)?.userType : ""
  );
  const [state, setState] = useState(data ? data?.userId : allUsers ? allUsers?.at(0)?._id : "");
  const [isMsg, setIsMsg] = useState("");
  const [isImage, setIsImage] = useState("");
  const [isImageExist, setIsImageExist] = useState(false);
  const [isData, setIsData] = useState((allUsers && allUsers?.at(0)) || null);
  const [isSearch, setIsSearch] = useState("");
  const [parnterShown, setParnterShown] = useState(false);

  useEffect(() => {
    setUserType(data ? data?.userType : allUsers ? allUsers?.at(0)?.userType : "");
    setState(data ? data?.threadId : allUsers ? allUsers?.at(0)?._id : "");
  }, [data]);

  const dispatch = useDispatch();
  useEffect(() => {
    socket.on(`newMessage/${state}`, (data) => {
      if (data) {
        dispatch(
          getAllSupportMessage({
            url: `${process.env.REACT_APP_API}/getChatFromThreadId/${state}`,
          })
        );
      }
    });
    socket.on("disconnect", () => {
      console.log("🔥: A user disconnected");
    });
  }, [socket, state]);
  useEffect(() => {
    dispatch(
      getAllSupportMessage({
        url: `${process.env.REACT_APP_API}/getChatFromThreadId/${state}`,
      })
    );
    scrollToBottom();
  }, [state, socket]);

  useEffect(() => {
    dispatch(
      getAllSupportUsers({
        url: parnterShown
          ? `${process.env.REACT_APP_API}/getAllpartnerProfile/${admin}/?page=${page}&search=${isSearch}`
          : `${process.env.REACT_APP_API}/getAllChatByAdmin?searchKey=${isSearch}`,
      })
    );
    scrollToBottom();
  }, [state, parnterShown, isSearch]);

  const checkUser = () => {
    let obj;

    allUsers &&
      allUsers?.find((x) => {
        if (x?._id === state) {
          if (Array.isArray(x?.userId)) {
            x?.userId?.find((user) => {
              if (user?._id !== localStorage.getItem("admin_id")) {
                obj = user;
              }
            });
          } else if (typeof x?.userId === "object" && x?.userId !== null) {
            if (x?.userId?._id !== localStorage.getItem("admin_id")) {
              obj = x?.userId;
            }
          }
        }
      });
    return obj;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const checkUser = () => {
      const userId = isData?.userId;

      if (Array.isArray(userId)) {
        const rr = userId?.find((x) => x?._id !== localStorage.getItem("admin_id"));
        return rr;
      } else if (typeof userId === "object" && userId !== null) {
        if (userId?._id !== localStorage.getItem("admin_id")) {
          return userId;
        }
      }
      return null;
    };
    const formData = new FormData();
    formData.append("from", checkUser()?._id);
    formData.append("userType", parnterShown ? "PARTNER" : isData?.userType);
    formData.append("message", isMsg);
    formData.append("image", isImage);
    formData.append("messageType", "ADMIN");
    dispatch(
      createMessage({
        url: `${process.env.REACT_APP_API}/createMessage`,
        data: formData,
      })
    ).then((data) => {
      setParnterShown(false);
      setIsSearch("");
      if (data?.payload?.success) {
        dispatch(
          getAllSupportMessage({
            url: `${process.env.REACT_APP_API}/getChatFromThreadId/${state}`,
          })
        );
        dispatch(
          getAllSupportUsers({
            url: `${process.env.REACT_APP_API}/getAllChatByAdmin?searchKey=${isSearch}`,
          })
        ).then((res) => {
          setState(res?.payload?.data?.at(0)?._id);
        });
        scrollToBottom();
        setIsMsg("");
        setIsImage("");
        setIsImageExist(false);
      }
    });
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Handle the selected file
      setIsImage(file);
      // console.log(file);
      setIsImageExist(true);
    }
  };

  const fetchData = async (searchQuery) => {
    dispatch(
      getAllSupportUsers({
        url: parnterShown
          ? `${process.env.REACT_APP_API}/getAllpartnerProfile/${admin}/?page=${page}&search=${searchQuery}`
          : `${process.env.REACT_APP_API}/getAllChatByAdmin?searchKey=${searchQuery}`,
      })
    );
    scrollToBottom();
  };

  const debouncedFetchData = useCallback(debounceFunction(fetchData, 300), []);

  useEffect(() => {
    debouncedFetchData(isSearch);
  }, [isSearch, debouncedFetchData]);

  const handleInputChange = (event) => {
    setIsSearch(event.target.value);
  };
  const groupedItems = (message && message.length > 0 && groupByDate(message)) || [];

  //-------------------------------------- infinite scroll -----------------------------------------------

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setPage(1);
    fetchMoreData(true);
  }, [isSearch]);

  const fetchMoreData = async (newSearch = false) => {
    dispatch(
      getAllSupportPartner({
        url: `${process.env.REACT_APP_API}/getAllpartnerProfile/${admin}/?page=${
          newSearch ? 1 : page
        }&search=${isSearch}`,
      })
    ).then((res) => {
      if (res?.payload?.page <= page && !newSearch) {
        setHasMore(false);
      }
      if (isSearch?.length === 0) {
        setHasMore(true);
        setItems([]);
      }
      if (newSearch) {
        setItems(res?.payload.data);
      } else {
        setItems([...items, ...res?.payload.data]);
      }
    });
    if (newSearch) {
      setPage(1);
    } else {
      setPage(page + 1);
    }
  };

  //-------------------------------------- infinite scroll -----------------------------------------------
  // console.log(items, "cscscsc", hasMore, page);
  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="nowrap"
      >
        <Grid item xs={2} sm={2} md={4} xl={4}>
          <MDBox
            //   mt={-3}
            py={1}
            px={2}
            // variant="gradient"
            // bgColor="success"
            borderRight={1}
            borderRightColor="success"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              height: "75vh",
              gap: 2,
              overflowY: "auto",
            }}
          >
            <MDBox
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Support Chat
              </MDTypography>
              <MDButton
                variant="gradient"
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: darkMode ? info.main : dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: darkMode ? info.main : dark.main,
                  },
                })}
                onClick={() => {
                  setIsSearch("");
                  setHasMore(true);
                  setParnterShown(!parnterShown);
                }}
              >
                {parnterShown ? (
                  <Icon sx={{ fontWeight: "bold" }}>
                    <FilterListOffIcon />
                  </Icon>
                ) : (
                  <Icon sx={{ fontWeight: "bold" }}>
                    <FilterListIcon />
                  </Icon>
                )}
              </MDButton>
            </MDBox>

            <MDInput
              placeholder="search here...... "
              type={"search"}
              fullWidth
              name="search"
              value={isSearch}
              onChange={handleInputChange}
            />

            {/* <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {allUsers &&
                allUsers?.length > 0 &&
                allUsers?.map((chat, index) => (
                  <ChatName
                    key={index}
                    taredId={chat?._id}
                    setState={setState}
                    active={state === chat?._id}
                    name={chat}
                    setIsData={setIsData}
                  />
                ))}
            </List> */}

            {parnterShown ? (
              <Box
                sx={{
                  height: "80%",
                  overflowY: "auto",
                }}
                id="scrollableDiv"
              >
                <InfiniteScroll
                  dataLength={items?.length || 0} // Length of current data
                  next={fetchMoreData} // Function to load more data
                  hasMore={hasMore} // Check if more data is available
                  loader={<h4>Loading...</h4>} // Display loader while fetching
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>You have seen it all!</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {items?.map((chat, index) => (
                    <ChatCard
                      key={index}
                      taredId={chat?._id}
                      setState={setState}
                      active={state === chat?._id}
                      name={chat}
                      setIsData={setIsData}
                      setUserType={setUserType}
                    />
                  ))}
                </InfiniteScroll>
              </Box>
            ) : allUsers && allUsers?.length > 0 ? (
              allUsers?.map((chat, index) => (
                <ChatCard
                  key={index}
                  taredId={chat?._id}
                  setState={setState}
                  active={state === chat?._id}
                  name={chat}
                  setIsData={setIsData}
                  setUserType={setUserType}
                  // icon={
                  //   <MDBox mr={2}>
                  //     <MDAvatar
                  //       src={`https://img.etimg.com/thumb/msid-108180856,width-640,resizemode-4,imgsize-63468/shraddha-kapoor-turns-37.jpg`}
                  //       alt="something here"
                  //       shadow="md"
                  //       objectFit="cover"
                  //     />
                  //   </MDBox>
                  // }
                />
              ))
            ) : (
              <MDTypography
                sx={({ palette: { dark, white } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              >
                user not found !
              </MDTypography>
            )}
          </MDBox>
        </Grid>
        <Grid item xs={10} sm={10} md={8} xl={8} height="80vh">
          <MDBox
            //   mt={-3}
            py={1.5}
            px={2}
            variant="gradient"
            bgColor="success"
            borderRadius="md"
            coloredShadow="success"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDBox display="flex" alignItems="center">
              <MDBox mr={1}>
                <MDAvatar
                  src={`${process.env.REACT_APP_URI}/${checkUser()?.image}`}
                  alt={checkUser()?.fullName}
                  shadow="md"
                />
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <MDTypography variant="h6" color="white" fontWeight="medium">
                  {checkUser()?.fullName}
                  {checkUser()?.userType}
                  {/* {console.log(checkUser(), "checkUser")} */}
                </MDTypography>
                <MDTypography variant="caption" color="text">
                  {checkUser()?.phoneNumber}
                </MDTypography>
              </MDBox>
            </MDBox>
            {/* <IconButton aria-label="edit" onClick={() => {}}>
              <More />
            </IconButton> */}
          </MDBox>
          {/* <MDBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            borderRadius="md"
            // coloredShadow="success"
            sx={{
              // position: "relative",
              // background: `url('https://camo.githubusercontent.com/cba518ead87b032dc6f1cbfc7fade27604449201ac1baf34d889f77f093f01ac/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67')`,
              // background: `url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png')`,
              // justifyContent: "center",
              // textAlign: "center",
              // flexDirection: "column",
              // gap: 2,
              width: "100%",
              // flex: 1,
              // height: "100%",
              height: `calc(100% - 20vh)`,
              // border: 1,
              // borderColor: "red",
              // opacity: 0.5,
            }}
          > */}
          <MDBox
            ref={bottomEl}
            sx={{
              // display: "flex",
              // justifyContent: "flex-end",
              // textAlign: "flex-end",
              // flexDirection: "column",
              // gap: 2,
              // flex: 1,
              height: `calc(100% - 20vh)`,
              overflowY: "auto",
              // opacity: 0.5,
              zIndex: 2,
              width: "100%",
              border: 1,

              // background: `rgba(55, 55, 55, .25) url('https://camo.githubusercontent.com/cba518ead87b032dc6f1cbfc7fade27604449201ac1baf34d889f77f093f01ac/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67')`,
            }}
          >
            {/* {console.log(message, "message")} */}
            {/* {message &&
              message.length > 0 &&
              message?.map((chat, i) => (
                <MessageTag data={chat} side={chat?.messageType === "ADMIN"} key={i} />
              ))} */}
            {Object.keys(groupedItems).map((dateKey) => (
              <div key={dateKey}>
                <MDBox sx={{ display: "flex", alignAItems: "center", justifyContent: "center" }}>
                  {" "}
                  <MDTypography
                    component="span"
                    fontSize={10}
                    textAlign="center"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white.main : dark.main,
                      bgcolor: darkMode ? dark.main : white.main,
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 2,
                      opacity: 0.8,
                    })}
                  >
                    {formatDateString(groupedItems[dateKey][0].createdAt)}
                  </MDTypography>
                </MDBox>
                {groupedItems[dateKey].map((chat, i) => (
                  <MessageTag data={chat} side={chat?.messageType === "ADMIN"} key={i} />
                ))}
              </div>
            ))}
          </MDBox>
          {/* <MDBox
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                height: `100%`,
                opacity: 0.3,
                zIndex: 1,
                width: "100%",
                background: `url('https://camo.githubusercontent.com/cba518ead87b032dc6f1cbfc7fade27604449201ac1baf34d889f77f093f01ac/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67')`,
              }}
            ></MDBox> */}
          {/* </MDBox> */}
          <MDBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            borderRadius="md"
            // coloredShadow="success"
            sx={{
              position: "relative",
              justifyContent: "center",
              textAlign: "center",
              // flexDirection: "column",
              gap: 0.5,
              width: "100%",
              height: `calc(100% - 70vh)`,

              // border: 1,
              // borderColor: "red",
            }}
            component="form"
            role="form"
            onSubmit={handleFormSubmit}
          >
            <MDBox
              sx={({ palette: { dark, white, info } }) => ({
                bgcolor: darkMode ? dark.main : white.main,
                color: darkMode ? white.main : dark.main,
                borderRadius: 2.5,
                position: "absolute",
                height: "10rem",
                top: isImageExist ? "-10rem" : "-5rem",
                opacity: isImageExist ? 1 : 0,
                left: 0,
                width: "100%",
                transition: "all 1s ease",
                p: 1.5,
              })}
            >
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "120px",
                    height: "120px",
                    margin: "0 0.5rem",
                  }}
                >
                  <img
                    className="Image"
                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    src={isImage && URL.createObjectURL(isImage)}
                  />
                </span>
                <span
                  className="cross"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsImage(null);
                    setIsImageExist(false);
                  }}
                >
                  <Cancel
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white?.main : dark.main,
                    })}
                  />
                </span>
              </div>
            </MDBox>
            <IconButton aria-label="" onClick={handleButtonClick}>
              <AttachFile
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              />
            </IconButton>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <TextField
              fullWidth
              id="input message"
              type="text"
              placeholder="Type your message"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
              value={isMsg}
              onChange={(e) => setIsMsg(e.target.value)}
            />
            <IconButton disabled={isMsg || isImage ? false : true} aria-label="" type="submit">
              <Send
                sx={({ palette: { dark, white, info, error } }) => ({
                  color: darkMode ? error.main : error.main,
                })}
              />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
};

export default ChatComponent;
ChatComponent.propTypes = {
  setIsOpen: PropTypes.func,
  data: PropTypes.string,
};
MessageTag.propTypes = {
  children: PropTypes.node,
  side: PropTypes.string,
  data: PropTypes.object,
};
ChatName.propTypes = {
  taredId: PropTypes.string,
  setState: PropTypes.func,
  active: PropTypes.bool,
  name: PropTypes.node,
  setIsData: PropTypes.func,
};
MessageTag.defaultProps = {
  left: "left",
};
